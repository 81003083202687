import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { IAdminData } from 'src/app/shared/interfaces/user.model';
import {
  ICreateSlideImg,
  ISlideImg,
} from 'src/app/shared/interfaces/utils.model';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiUrl = environment.apiUrl; // Replace with your actual API URL
  private profileSubject: BehaviorSubject<IAdminData | null>;
  public currentProfile: Observable<IAdminData | null>;
  constructor(private http: HttpClient) {
    this.profileSubject = new BehaviorSubject<IAdminData | null>(
      JSON.parse(localStorage.getItem('profile')!)
    );
    this.currentProfile = this.profileSubject.asObservable();
  }

  updateProfileData(data: IAdminData) {
    this.profileSubject.next(data);
  }

  getProfile() {
    return this.http
      .get<any>(`${this.apiUrl}user/profile`)
      .pipe(catchError(this.handleError));
  }

  changePassword(body: { password: string; oldPassword: string }) {
    return this.http
      .patch<any>(`${this.apiUrl}auth/changePassword`, body)
      .pipe(catchError(this.handleError));
  }

  getSlideShow(queryParams: any) {
    let params = new HttpParams();

    for (let key in queryParams) {
      const value = queryParams[key as keyof any];
      if (value !== null && value !== undefined) {
        params = params.set(key, value.toString());
      }
    }
    const requestOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${this.apiUrl}backoffice/slide/list`, requestOptions)
      .pipe(catchError(this.handleError));
  }
  getSlideDetail(id: string) {
    let params = new HttpParams();

    params = params.set('slideId', id.toString());

    const requestOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${this.apiUrl}backoffice/slide/detail`, requestOptions)
      .pipe(catchError(this.handleError));
  }

  createSlide(body: FormData) {
    return this.http
      .post(`${this.apiUrl}backoffice/slide`, body)
      .pipe(catchError(this.handleError));
  }

  editSlide(body: FormData, id: string) {
    let params = new HttpParams();

    params = params.set('slideId', id.toString());

    const requestOptions = {
      params: params,
    };

    return this.http
      .put(`${this.apiUrl}backoffice/slide`, body, requestOptions)
      .pipe(catchError(this.handleError));
  }
  removeSlide(body: { password: string }, id: string) {
    let params = new HttpParams();

    params = params.set('slideId', id.toString());

    const requestOptions = {
      params: params,
      body: body,
    };

    return this.http
      .delete(`${this.apiUrl}backoffice/slide`, requestOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      console.error('service::error ', error.error);
    }
    let errorMessage = '';
    if (Array.isArray(error.error.statusMessage)) {
      errorMessage = error.error.statusMessage.toString();
    } else {
      errorMessage = error.error.statusMessage;
    }
    // console.error('offer.service::errorMessage:: ', errorMessage);
    return throwError(() => error.error);
  }
}
